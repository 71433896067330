import Logo from "src/components/logo";
import { OrganizationProfile } from "src/routes/elements";
import { Stack } from "@mui/material";

// ----------------------------------------------------------------------

export default function OrganizationProfileLayout() {
  return (
    <Stack gap={3}>
      <Logo
        sx={{
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <OrganizationProfile isPublic />
    </Stack>
  );
}
