// @mui

import VerifyLayout from "src/layouts/verify/VerifyLayout";

// layouts
// ----------------------------------------------------------------------

export default function Verify() {
  return (
    <VerifyLayout>
      <></>
    </VerifyLayout>
  );
}
