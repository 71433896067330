// @mui
import { List, Stack } from "@mui/material";
// locales
import { useLocales } from "../../../locales";
//
import { NavListProps, NavSectionProps } from "../types";
import { StyledSubheader } from "./styles";
import NavList from "./NavList";
import { useAuthContext } from "src/auth/useAuthContext";
import { useCallback } from "react";
import _ from "lodash";

// ----------------------------------------------------------------------

export default function NavSectionVertical({
  data,
  sx,
  ...other
}: NavSectionProps) {
  const { translate } = useLocales();
  const { user } = useAuthContext();

  const currentRole = user?.role;

  const isEligible = useCallback(
    (group: { subheader: string; items: NavListProps[] }) => {
      const rolesArray = _.map(group.items, "roles");

      // Concatenate roles and remove duplicates
      const uniqueRoles = _.uniq(_.flatten(rolesArray));

      return _.compact(uniqueRoles);
    },
    []
  );

  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.items[0].title;
        const permittedRoles = isEligible(group);
        const isPermitted =
          typeof permittedRoles !== "undefined" &&
          permittedRoles.includes(currentRole);

        return (
          <List key={key} disablePadding sx={{ px: 2 }}>
            {isPermitted && group.subheader && (
              <StyledSubheader disableSticky>{`${translate(
                group.subheader
              )}`}</StyledSubheader>
            )}

            {group.items.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
