// @mui
import { styled } from "@mui/material/styles";

// ----------------------------------------------------------------------

export const StyledRoot = styled("main")(() => ({
  height: "100%",
  display: "flex",
  position: "relative",
}));

export const StyledSection = styled("div")(({ theme }) => ({
  position: "relative",
  padding: "60px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  [theme.breakpoints.up("md")]: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "0",
  },
}));
