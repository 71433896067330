import { TFunctionDetailedResult } from "i18next";
import _ from "lodash";

interface ErrorObject {
  code: string | number;
  msg: string;
}

interface ErrorPayload {
  errors?: ErrorObject[];
  code?: string | number;
  message?: string;
}

export function formatError(
  e: ErrorPayload,
  translate: (text: any, options?: any) => TFunctionDetailedResult<object>
): string {
  const defaultErrorMessage = `${translate("error_code.9000000")}`;

  // Check if 'e' is an object
  if (!_.isObject(e)) {
    return defaultErrorMessage;
  }

  // If 'e' has 'errors' array, process each error and concatenate messages
  if (_.isArray(e.errors) && e.errors.length > 0) {
    const errorMessages = _.map(e.errors, (error: ErrorObject) => {
      // Check if the error object has the expected properties
      if (
        !_.isObject(error) ||
        (!_.isString(error.code) && !_.isNumber(error.code)) ||
        !error.msg
      ) {
        return defaultErrorMessage;
      }

      // At this point, you can safely access the code property as a string
      const errorCode = String(error.code);
      return `${translate("error_code." + errorCode)}`;
    });

    // Concatenate error messages with '.'
    return errorMessages.join(". ");
  }

  // If 'e' has a single error object with 'code' and 'message' properties
  if ((!_.isString(e.code) && !_.isNumber(e.code)) || !_.isString(e.message)) {
    return defaultErrorMessage;
  }

  // At this point, you can safely access the code property as a string
  const errorCode = String(e.code);
  return `${translate("error_code." + errorCode)}`;
}
