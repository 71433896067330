const el = {
  login: `Σύνδεση`,
  general: {
    delete_success: `Επιτυχής διαγραφή!`,
  },
  form: {
    general: {
      save_changes: `Αποθήκευση αλλαγών`,
      create_success: `Επιτυχής δημιουργία!`,
      update_success: `Επιτυχής επεξεργασία!`,
      something_wrong: `Κάτι πήγε στραβά!`,
    }
  },
  books: {
    title: `Βιβλια`,
    nav_title: `Βιβλία`,
    list: `Όλα τα βιβλία`,
    archived: `Αρχείο`,
    create: `Δημιουργία βιβλίου`,
  },
  videos: {
    title: `Οπτικο υλικο`,
    nav_title: `Βίντεο`,
    list: `Όλα τα βίντεο`,
    archived: `Αρχείο`,
    create: `Δημιουργία νέου`,
  },
  reports: {
    title: `Dashboard`,
    nav_title: `Στατιστικά`
  },
  categories: {
    title: `Κατηγοριες`,
    nav_title: `Κατηγορίες`,
    list: `Όλες οι κατηγορίες`,
    archived: `Αρχειοθετημένες κατηγορίες`,
    create: `Δημιουργία κατηγορίας`,
    create_title: `Δημιουργία νέας κατηγορίας`,
    new_category: `Νέα κατηγορία`,
    update_title: `Επεξεργασία κατηγορίας`,
    category: `Κατηγορία`,
    form: {
      name: `Όνομα κατηγορίας`,
      description: `Περιγραφή κατηγορίας`,
      isArchived: `Αρχειοθετημένη κατηγορία`,
      isPublished: `Ενεργή κατηγορία`,
      submit: `Δημιουργία κατηγορίας`
    }
  },
  roles: {
    super_admin: `Κύριος διαχειριστής`,
    simple_admin: `Διαχειριστής`,
  }
};

export default el;
