import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// @mui
import LoadingButton from "@mui/lab/LoadingButton";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// routes
import { RouterLink } from "src/routes/components";
// assets
import { PasswordIcon } from "src/assets/icons";
// components
import Iconify from "src/components/iconify";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import { PATH_AUTH } from "src/routes/paths";
import { useAuthContext } from "src/auth/useAuthContext";
import { useLocales } from "src/locales";
import { Alert } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { formatError } from "src/utils/formatErrorMessage";

// ----------------------------------------------------------------------

type FormValuesProps = {
  password: string;
  repeatPassword: string;
  afterSubmit?: string;
};

export default function AuthChangePasswordForm() {
  const { changePassword } = useAuthContext();
  const { translate } = useLocales();
  const navigate = useNavigate();

  let [searchParams] = useSearchParams();
  const code = searchParams.get("code") ?? "";

  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    repeatPassword: Yup.string()
      .min(6, "Repeat password must be at least 6 characters")
      .required("Repeat password is required"),
  });

  const defaultValues = {
    password: "",
    repeatPassword: "",
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(ChangePasswordSchema),
    defaultValues,
  });

  const {
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await changePassword(data.password, code);

      navigate(PATH_AUTH.login);
    } catch (e) {
      const errorMessage = formatError(e, translate);

      setError("afterSubmit", {
        ...e,
        message: errorMessage,
      });
    }
  });

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      {!!errors.afterSubmit && (
        <Alert sx={{ width: "100%" }} severity="error">
          {errors.afterSubmit.message}
        </Alert>
      )}

      <RHFTextField type="password" name="password" label="Password" />
      <RHFTextField
        type="password"
        name="repeatPassword"
        label="Retype password"
      />

      <LoadingButton
        fullWidth
        color="inherit"
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting || isSubmitSuccessful}
        sx={{
          mt: 3,
          bgcolor: "text.primary",
          color: (theme) =>
            theme.palette.mode === "light" ? "common.white" : "grey.800",
          "&:hover": {
            bgcolor: "text.primary",
            color: (theme) =>
              theme.palette.mode === "light" ? "common.white" : "grey.800",
          },
        }}
      >
        Reset password
      </LoadingButton>

      <Link
        component={RouterLink}
        href={PATH_AUTH.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <Iconify icon="solar:alt-arrow-left-linear" width={16} />
        Return to Sign in
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <PasswordIcon sx={{ height: 96 }} />

      <Stack spacing={1} sx={{ my: 5, alignItems: "center" }}>
        <Typography variant="h3">Change your password</Typography>

        <Typography
          variant="body2"
          sx={{ color: "text.secondary", textAlign: "center" }}
        >
          Your password should be at least 6 characters long and include a mix
          of letters (both uppercase and lowercase), numbers, and special
          characters.
        </Typography>
      </Stack>
    </>
  );

  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Stack sx={{ maxWidth: "450px", justifyContent: "center" }}>
        {renderHead}

        {renderForm}
      </Stack>
    </FormProvider>
  );
}
