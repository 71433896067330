// @mui
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
// routes
import { RouterLink } from "src/routes/components";
// assets
// components
import Iconify from "src/components/iconify";
import { PATH_AUTH } from "src/routes/paths";
import VerifyIcon from "src/assets/icons/VerifyIcon";
import { Box, LinearProgress } from "@mui/material";
import { StyledRoot } from "src/layouts/verify/styles";
import { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "src/auth/useAuthContext";
import { useSearchParams } from "react-router-dom";

// ----------------------------------------------------------------------

export default function AuthForgotPasswordForm() {
  const [isLoading, setIsLoading] = useState(true);
  const [message, setMessage] = useState<"success" | "error" | "expired">();

  const { verify } = useAuthContext();

  let [searchParams] = useSearchParams();
  const code = searchParams.get("code") ?? "";

  const onSubmit = useCallback(
    async (code: string) => {
      try {
        await verify(code);
        setMessage("success");
      } catch (error) {
        setMessage("error");
      } finally {
        setIsLoading(false);
      }
    },
    [verify]
  );

  useEffect(() => {
    onSubmit(code);
  }, [code, onSubmit]);

  const renderForm = (
    <Stack spacing={3} alignItems="center">
      <Link
        component={RouterLink}
        href={PATH_AUTH.login}
        color="inherit"
        variant="subtitle2"
        sx={{
          alignItems: "center",
          display: "inline-flex",
        }}
      >
        <Iconify icon="solar:alt-arrow-left-linear" width={16} />
        Return to Sign in
      </Link>
    </Stack>
  );

  const renderHead = (
    <>
      <Box sx={{ textAlign: "center", width: "100%" }}>
        <VerifyIcon />
      </Box>

      <Stack spacing={1} sx={{ my: 5, alignItems: "center" }}>
        <Typography variant="h3">Verify account</Typography>

        {isLoading ? (
          <StyledRoot
            sx={{
              ...{
                width: `calc(100%)`,
                my: "45px !important",
                justifyContent: "center",
              },
            }}
          >
            <LinearProgress color="inherit" sx={{ width: 1, maxWidth: 360 }} />
          </StyledRoot>
        ) : (
          <Stack>
            {message === "success" && (
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", textAlign: "center" }}
              >
                Congratulations! Your email has been successfully verified.
              </Typography>
            )}

            {message === "error" && (
              <Typography
                variant="body2"
                sx={{ color: "text.secondary", textAlign: "center" }}
              >
                We're sorry, but there was an issue verifying your email
                address. Please double-check the verification link. If you
                continue to experience problems, please contact our support team
                for assistance.
              </Typography>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );

  return (
    <Stack sx={{ maxWidth: "450px", justifyContent: "center" }}>
      {renderHead}

      {renderForm}
    </Stack>
  );
}
