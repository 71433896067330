// @mui
import { Stack, Typography } from "@mui/material";
import { useLocales } from "src/locales";
// layouts
import LoginLayout from "../../layouts/login";
//
import AuthLoginForm from "./AuthLoginForm";
// ----------------------------------------------------------------------

export default function Login() {
  const { translate } = useLocales();

  return (
    <LoginLayout>
      <Stack spacing={2} sx={{ mb: 5, position: "relative" }}>
        <Typography variant="h3">{`${translate("login")}`}</Typography>
      </Stack>
      <AuthLoginForm />
    </LoginLayout>
  );
}
