import React, { ReactElement, useCallback, useState } from "react";

import {
  Box,
  PopperProps,
  Stack,
  SxProps,
  Tooltip,
  TooltipProps,
} from "@mui/material";

// -------------------------------------------------------------------------------------------------------
export interface IEllipsisTooltipProps {
  label: string;
  children: ReactElement;
  popperProps?: Partial<PopperProps>;
  placement?: TooltipProps["placement"];
  hasArrow?: boolean;
  sx?: SxProps;
  /**
   * Append extra tooltip info on hover
   * @default: undefined
   */
  extraInfo?: string;
  expandableRows?: number;
}
// -------------------------------------------------------------------------------------------------------
const defaultPopperProps: Partial<PopperProps> = {
  sx: { zIndex: (theme) => theme.zIndex.tooltip },
  modifiers: [
    {
      name: "offset",
      options: {
        offset: [0, -10],
      },
    },
    {
      name: "hide",
    },
  ],
};
// -------------------------------------------------------------------------------------------------------
function EllipsisTooltip(props: IEllipsisTooltipProps) {
  const {
    label,
    children,
    hasArrow = true,
    popperProps = defaultPopperProps,
    placement = "top",
    extraInfo,
    sx,
    expandableRows = 1,
  } = props;

  const [toolTipContent, setTooltipContent] = useState<
    JSX.Element | undefined
  >();

  const showTooltip = useCallback(
    ({ currentTarget }: React.MouseEvent<HTMLElement>) => {
      if (
        expandableRows === 1
          ? currentTarget.scrollWidth > currentTarget.clientWidth
          : currentTarget.scrollHeight > currentTarget.clientHeight
      ) {
        const separatorContent =
          extraInfo && label ? <hr style={{ width: "100%" }} /> : null;

        setTooltipContent(
          <Stack direction="column" width="100%">
            {label}
            {separatorContent}
            {extraInfo}
          </Stack>
        );
      } else if (extraInfo) {
        setTooltipContent(
          <Stack direction="column" width="100%">
            {extraInfo}
          </Stack>
        );
      } else {
        setTooltipContent(undefined);
      }
    },
    [expandableRows, extraInfo, label]
  );

  const Body = (
    <Box
      style={{
        overflow: "hidden",
        textOverflow: "ellipsis",
        WebkitLineClamp: expandableRows,
        WebkitBoxOrient: "vertical",
        display: "-webkit-box",
      }}
      sx={sx}
    >
      {children}
    </Box>
  );

  return (
    <Tooltip
      onMouseEnter={showTooltip}
      arrow={hasArrow}
      placement={placement}
      title={toolTipContent}
      PopperProps={popperProps}
      enterDelay={500}
    >
      {expandableRows !== 1 ? Body : children}
    </Tooltip>
  );
}

export default EllipsisTooltip;
// -------------------------------------------------------------------------------------------------------
