import { ReactNode } from "react";
import { Navigate } from "react-router-dom";
// components
import LoadingScreen from "../components/loading-screen";
//
import { useAuthContext } from "./useAuthContext";
import _ from "lodash";

// ----------------------------------------------------------------------

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized, provider } = useAuthContext();
  const isProvider = !_.isNil(provider?._id);

  if (isAuthenticated) {
    return (
      <Navigate
        to={isProvider ? "/dashboard/general/provider-dashboard" : "/dashboard"}
      />
    );
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <> {children} </>;
}
