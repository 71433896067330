import { Helmet } from "react-helmet-async";
import ChangePassword from "src/sections/auth/ChangePassword";
// sections
// ----------------------------------------------------------------------

export default function ChangePasswordPage() {
  return (
    <>
      <Helmet>
        <title> Change password | Keon Energy</title>
      </Helmet>

      <ChangePassword />
    </>
  );
}
