// @mui

import ChangePasswordLayout from "src/layouts/change-password";
// layouts
// ----------------------------------------------------------------------

export default function ChangePassword() {
  return (
    <ChangePasswordLayout>
      <></>
    </ChangePasswordLayout>
  );
}
