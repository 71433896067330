import _ from "lodash";

export type Environment = "demo" | "dev" | "prod";

export const GOOGLE_MAPS_API_KEY = "AIzaSyBQZNQz0BCq6XdcYM5eUTpt2Ne0spQ5S74";

export interface IConfig {
  SERVICE: {
    baseURL: string;
    spacesEnabled?: boolean;
    vehiclesEnabled?: boolean;
    chargersEnabled?: boolean;
    metersEnabled?: boolean;
    pvsEnabled?: boolean;
    devMode?: boolean;
    registrationEnabled?: boolean;
    env: Environment;
  };
}

function getUrlParameters(): { [key: string]: string } {
  let sPageURL = window.location.search.substring(1);
  let sURLVariables = sPageURL.split("&");
  let res: { [key: string]: string } = {};
  for (let i = 0; i < sURLVariables.length; i++) {
    let sParameter = sURLVariables[i].split("=");
    res[sParameter[0]] = sParameter[1];
  }
  return res;
}

let config: IConfig = (window as any).keonConfig;
if (_.isNil(config)) {
  // Init base configuration
  config = {
    SERVICE: {
      baseURL: "https://api-dev.keon.energy/api/v1",
      devMode: true,
      spacesEnabled: false,
      vehiclesEnabled: true,
      chargersEnabled: true,
      metersEnabled: true,
      pvsEnabled: true,
      registrationEnabled: true,
      env: "dev",
    },
  };

  console.log(window.location.host);

  switch (window.location.host) {
    case "demo.keon.energy":
    case "keonapp-demo.keon.energy":
      config.SERVICE.devMode = false;
      config.SERVICE.baseURL = "https://api-demo.keon.energy/api/v1";
      config.SERVICE.spacesEnabled = false;
      config.SERVICE.vehiclesEnabled = true;
      config.SERVICE.chargersEnabled = true;
      config.SERVICE.metersEnabled = true;
      config.SERVICE.pvsEnabled = true;
      config.SERVICE.env = "demo";
      break;
    case "app.keon.energy":
      config.SERVICE.devMode = false;
      config.SERVICE.registrationEnabled = false;
      config.SERVICE.baseURL = "https://api.keon.energy/api/v1";
      config.SERVICE.spacesEnabled = false;
      config.SERVICE.vehiclesEnabled = true;
      config.SERVICE.chargersEnabled = true;
      config.SERVICE.metersEnabled = true;
      config.SERVICE.pvsEnabled = true;
      config.SERVICE.env = "prod";
      break;
  }

  let urlParams = getUrlParameters();

  // Be able to select externally the backend baseURL
  if (
    window.location.host.startsWith("keonapp-") ||
    window.location.host === "localhost:8083"
  ) {
    if (!_.isEmpty(urlParams["configService"])) {
      switch (urlParams["configService"]) {
        case "demo":
          config.SERVICE.baseURL = "https://api-dev.keon.energy/api/v1";
          break;
        case "prod":
          config.SERVICE.baseURL = "https://api.keon.energy/api/v1";
          break;
      }
    }
  }

  if (!_.isEmpty(urlParams["devMode"])) {
    config.SERVICE.devMode = urlParams["devMode"] === "true";
  }

  if (!_.isEmpty(urlParams["registrationEnabled"])) {
    config.SERVICE.registrationEnabled =
      urlParams["registrationEnabled"] === "true";
  }

  if (!_.isEmpty(urlParams["spacesEnabled"])) {
    config.SERVICE.spacesEnabled = urlParams["spacesEnabled"] === "true";
  }

  if (!_.isEmpty(urlParams["vehiclesEnabled"])) {
    config.SERVICE.vehiclesEnabled = urlParams["vehiclesEnabled"] === "true";
  }

  if (!_.isEmpty(urlParams["chargersEnabled"])) {
    config.SERVICE.chargersEnabled = urlParams["chargersEnabled"] === "true";
  }

  if (!_.isEmpty(urlParams["metersEnabled"])) {
    config.SERVICE.metersEnabled = urlParams["metersEnabled"] === "true";
  }

  if (!_.isEmpty(urlParams["pvsEnabled"])) {
    config.SERVICE.pvsEnabled = urlParams["pvsEnabled"] === "true";
  }

  // Global save of the configuration
  (window as any).keonConfig = config;
}

export default config;
