// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, Dispatch } from "@reduxjs/toolkit";
import _ from "lodash";
import axios from "src/utils/axios";

export interface OrganizationState {
  isLoadingOrganizations: boolean;
  error: any;
  organizations: any[];
}

const initialState: OrganizationState = {
  isLoadingOrganizations: false,
  error: null,
  organizations: [],
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingOrganizations = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingOrganizations = false;
      state.error = action.payload;
    },

    // GET ENTITY VALUES
    getOrganizationsSuccess(state, action) {
      state.isLoadingOrganizations = false;
      state.organizations = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {} = organizationSlice.actions;

export default organizationSlice.reducer;

// ----------------------------------------------------------------------

export function getOrganizations() {
  return async (dispatch: Dispatch) => {
    dispatch(organizationSlice.actions.startLoading());
    try {
      const response = await axios.get(
        `/standard/organization/organizationsLite`
      );

      if (!_.isNil(response?.data?.organizations)) {
        dispatch(
          organizationSlice.actions.getOrganizationsSuccess(
            response.data.organizations
          )
        );
      }
    } catch (error) {
      dispatch(organizationSlice.actions.hasError(error));
    }
  };
}
