import AuthChangePasswordForm from "src/sections/auth/AuthChangePasswordForm";
import { StyledRoot, StyledSection } from "./styles";
import Logo from "src/components/logo";

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function ChangePasswordLayout({
  children,
  illustration,
  title,
}: Props) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: "absolute",
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection>
        <AuthChangePasswordForm />
      </StyledSection>
    </StyledRoot>
  );
}
