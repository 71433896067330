import { memo } from "react";
import { Stack, SxProps } from "@mui/material";
//
import { NavSectionProps, NavListProps } from "../types";
import NavList from "./NavList";
import _ from "lodash";

// ----------------------------------------------------------------------

function NavSectionMini({ data, sx, ...other }: NavSectionProps) {
  return (
    <Stack
      spacing={0.5}
      alignItems="center"
      sx={{
        mt: 2,
        px: 0.75,
        ...sx,
      }}
      {...other}
    >
      {_.map(data, (group, index) => (
        <Items
          key={group.subheader}
          items={group.items}
          isLastGroup={index + 1 === data.length}
        />
      ))}
    </Stack>
  );
}

export default memo(NavSectionMini);

// ----------------------------------------------------------------------

type ItemsProps = {
  items: NavListProps[];
  isLastGroup: boolean;
  sxProps?: SxProps;
};

function Items({ items, isLastGroup, sxProps }: ItemsProps) {
  return (
    <>
      {items.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={1}
          hasChild={!!list.children}
          sxProps={sxProps}
        />
      ))}

      {/* {!isLastGroup && (
        <Box
          sx={{
            width: 24,
            height: "1px",
            bgcolor: "divider",
            my: "8px !important",
          }}
        />
      )} */}
    </>
  );
}
