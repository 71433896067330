// @mui
import { styled, alpha } from "@mui/material/styles";
import { Box, Stack, Typography } from "@mui/material";
import { useLocales } from "src/locales";
import { Role, RoleTitle } from "src/enum/roles";
// auth
import { useAuthContext } from "../../../auth/useAuthContext";
// components
import { CustomAvatar } from "../../../components/custom-avatar";
import EllipsisTooltip from "src/components/overflow-ellipsis/ellipsis-tooltip";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

export default function NavAccount() {
  const { user } = useAuthContext();
  const { translate } = useLocales();

  return (
    <StyledRoot>
      <CustomAvatar
        src={user?.profilePictureURL}
        alt={user?.displayName}
        firstName={user?.firstName}
        lastName={user?.lastName}
        sx={{ fontSize: "15px" }}
      />

      <Box sx={{ ml: 2, minWidth: 0 }}>
        <EllipsisTooltip label={user?.displayName} expandableRows={2}>
          <Typography variant="subtitle2">{user?.displayName}</Typography>
        </EllipsisTooltip>

        <Stack direction="row" gap={0.5} alignItems="center">
          {user?.role === Role.StandardUser ? (
            <span style={{ fontSize: "14px" }}>🚀</span>
          ) : undefined}
          {user?.role === Role.EnergyProvider ? (
            <span style={{ fontSize: "14px" }}>⚡</span>
          ) : undefined}
          <Typography variant="body2" noWrap sx={{ color: "text.secondary" }}>
            {`${translate(`roles.${RoleTitle[user?.role as Role]}`)}`}
          </Typography>
        </Stack>
      </Box>
    </StyledRoot>
  );
}
