import { Helmet } from "react-helmet-async";
import Verify from "src/sections/auth/Verify";
// sections
// ----------------------------------------------------------------------

export default function VerifyPage() {
  return (
    <>
      <Helmet>
        <title> Verify | Keon Energy</title>
      </Helmet>

      <Verify />
    </>
  );
}
