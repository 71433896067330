// eslint-disable-next-line import/no-extraneous-dependencies
import { createSlice, Dispatch } from "@reduxjs/toolkit";
import axios from "src/utils/axios";

export interface EnergyProviderState {
  isLoadingEnergyProviders: boolean;
  error: any;
  energyProviders: any[];
}

const initialState: EnergyProviderState = {
  isLoadingEnergyProviders: false,
  error: null,
  energyProviders: [],
};

export const energyProviderSlice = createSlice({
  name: "energyProvider",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoadingEnergyProviders = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoadingEnergyProviders = false;
      state.error = action.payload;
    },

    // GET ENTITY VALUES
    getEnergyProvidersSuccess(state, action) {
      state.isLoadingEnergyProviders = false;
      state.energyProviders = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {} = energyProviderSlice.actions;

export default energyProviderSlice.reducer;

// ----------------------------------------------------------------------

export function getEnergyProviders() {
  return async (dispatch: Dispatch) => {
    dispatch(energyProviderSlice.actions.startLoading());
    try {
      const response = await axios.get(`/providers`);
      dispatch(
        energyProviderSlice.actions.getEnergyProvidersSuccess(response.data)
      );
    } catch (error) {
      dispatch(energyProviderSlice.actions.hasError(error));
    }
  };
}
