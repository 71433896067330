import {
  createContext,
  useEffect,
  useReducer,
  useCallback,
  useMemo,
} from "react";
import _ from "lodash";

// utils
import axios from "../utils/axios";
import localStorageAvailable from "../utils/localStorageAvailable";
//
import { getUserRoleFromToken, isValidToken, setSession } from "./utils";
import {
  ActionMapType,
  AuthProviderType,
  AuthStateType,
  AuthUserType,
  JWTContextType,
} from "./types";
import { Role } from "src/enum/roles";
import {
  IAccountProfileGeneral,
  IProviderInfo,
  IUserAccountChangePassword,
} from "src/@types/user";
// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = "INITIAL",
  EDIT_USER = "EDIT_USER",
  EDIT_PROVIDER_INFO = "EDIT_PROVIDER_INFO",
  LOGIN = "LOGIN",
  REGISTER = "REGISTER",
  LOGOUT = "LOGOUT",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  ENERGY_PROVIDER = "ENERGY_PROVIDER",
  ADD_USER_PROFILE_PHOTO = "ADD_USER_PROFILE_PHOTO",
  REMOVE_USER_PROFILE_PHOTO = "REMOVE_USER_PROFILE_PHOTO",
  REMOVE_PROVIDER_LOGO = "REMOVE_PROVIDER_LOGO",
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    user: AuthUserType;
  };
  [Types.EDIT_USER]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.ENERGY_PROVIDER]: {
    provider: AuthProviderType;
  };
  [Types.EDIT_PROVIDER_INFO]: {
    provider: AuthProviderType;
  };
  [Types.LOGOUT]: undefined;
  [Types.ADD_USER_PROFILE_PHOTO]: {
    user: AuthUserType;
  };
  [Types.REMOVE_USER_PROFILE_PHOTO]: {
    user: AuthUserType;
  };
  [Types.REMOVE_PROVIDER_LOGO]: {
    provider: AuthProviderType;
  };
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  user: null,
  provider: null,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.EDIT_USER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN || action.type === Types.REGISTER) {
    return {
      ...state,
      isAuthenticated: true,
      user: action.payload.user,
    };
  }
  if (action.type === Types.ENERGY_PROVIDER) {
    return {
      ...state,
      provider: action.payload.provider,
    };
  }
  if (action.type === Types.EDIT_PROVIDER_INFO) {
    return {
      ...state,
      provider: action.payload.provider,
    };
  }
  if (action.type === Types.ADD_USER_PROFILE_PHOTO) {
    return {
      ...state,
      user: { ...state.user, profilePictureURL: action.payload.user?.imgUrl },
    };
  }
  if (action.type === Types.REMOVE_USER_PROFILE_PHOTO) {
    return {
      ...state,
      user: { ...state.user, profilePictureURL: null },
    };
  }
  if (action.type === Types.REMOVE_PROVIDER_LOGO) {
    return {
      ...state,
      provider: { ...state.provider, logoURL: null },
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isAuthenticated: false,
      user: null,
      provider: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

export const AuthContext = createContext<JWTContextType | null>(null);

// ----------------------------------------------------------------------

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const storageAvailable = localStorageAvailable();

  const initialize = useCallback(async () => {
    try {
      const accessToken = storageAvailable
        ? localStorage.getItem("accessToken")
        : "";

      if (accessToken && isValidToken(accessToken)) {
        setSession(accessToken);

        const tokenUserRole = getUserRoleFromToken(accessToken);

        try {
          // Get current user
          let userResponse;
          let providerResponse;

          userResponse = await axios.get("/auth/me");

          if (tokenUserRole === Role.EnergyProvider) {
            try {
              providerResponse = await axios.get("/provider/provider");

              const { data: provider } = providerResponse;

              dispatch({
                type: Types.ENERGY_PROVIDER,
                payload: {
                  provider,
                },
              });
            } catch (error) {
              // throw new Error(error);
            }
          }

          const { access_token, refresh_token, user } = userResponse.data;

          localStorage.setItem("accessToken", access_token);
          localStorage.setItem("refreshToken", refresh_token);

          setSession(access_token);

          // Set displayName
          user.displayName = _.compact([user.firstName, user.lastName]).join(
            " "
          );

          dispatch({
            type: Types.INITIAL,
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } catch (error) {
          throw error;
        }
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: false,
          user: null,
        },
      });
    }
  }, [storageAvailable]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    try {
      // Login
      const loginResponse = await axios.post("/auth/login", {
        email,
        password,
      });

      const { access_token, refresh_token, user } = loginResponse.data;

      localStorage.setItem("accessToken", access_token);
      localStorage.setItem("refreshToken", refresh_token);

      setSession(access_token);

      const tokenUserRole = getUserRoleFromToken(access_token);

      if (tokenUserRole === Role.EnergyProvider) {
        try {
          const providerResponse = await axios.get("/provider/provider");

          const { data: provider } = providerResponse;

          dispatch({
            type: Types.ENERGY_PROVIDER,
            payload: {
              provider,
            },
          });
        } catch (error) {}
      }

      // Set displayName
      user.displayName = _.compact([user.firstName, user.lastName]).join(" ");

      dispatch({
        type: Types.LOGIN,
        payload: {
          user,
        },
      });
    } catch (error) {
      throw error;
    }
  }, []);

  // REGISTER
  const register = useCallback(
    async (
      email: string,
      password: string,
      firstName: string,
      lastName: string
    ) => {
      try {
        return await axios.post("/auth/register", {
          firstName,
          lastName,
          email,
          password,
          acceptUseTerms: true,
        });
      } catch (error) {
        throw error;
      }
    },
    []
  );

  // FORGOT PASSWORD
  const forgotPassword = useCallback(async (email: string) => {
    try {
      return await axios.post("/auth/request-reset-pass", {
        email,
      });
    } catch (error) {
      throw error;
    }
  }, []);

  // CHANGE PASSWORD
  const changePassword = useCallback(async (password: string, code: string) => {
    try {
      return await axios.post(`/auth/reset-pass`, {
        password,
        code,
      });
    } catch (error) {
      throw error;
    }
  }, []);

  // VERIFY
  const verify = useCallback(async (code: string) => {
    try {
      return await axios.post(`/auth/verify-email`, { code });
    } catch (error) {
      throw error;
    }
  }, []);

  // LOGOUT
  const logout = useCallback(() => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });
  }, []);

  // EDIT USER PROFILE
  const editProfile = useCallback(async (data: IAccountProfileGeneral) => {
    try {
      const userResponse = await axios.put(`/auth/profile`, data);

      const { user } = userResponse.data;

      // Set displayName
      user.displayName = _.compact([user.firstName, user.lastName]).join(" ");

      dispatch({
        type: Types.EDIT_USER,
        payload: {
          user: user,
        },
      });

      return user;
    } catch (e) {
      throw e;
    }
  }, []);

  // EDIT PROVIDER INFO
  const editProviderInfo = useCallback(
    async (id: string, data: IProviderInfo) => {
      try {
        const providerResponse = await axios.put(
          `/provider/updateProvider`,
          data
        );

        const { data: provider } = providerResponse;

        dispatch({
          type: Types.EDIT_PROVIDER_INFO,
          payload: {
            provider,
          },
        });

        return provider;
      } catch (e) {
        throw e;
      }
    },
    []
  );

  // EDIT USER PASSWORD
  const editPassword = useCallback(async (data: IUserAccountChangePassword) => {
    try {
      const userResponse = await axios.put(`/auth/password`, {
        password: data.password,
      });

      const { user } = userResponse.data;

      return user;
    } catch (error) {
      throw error;
    }
  }, []);

  // UPDATE PROFILE PHOTO
  const updateProfilePhoto = useCallback(async (file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return axios
      .put(`/auth/image`, formData, config)
      .then((res) => {
        const { imgUrl } = res.data;

        const img = `${imgUrl}?rand=${Math.random()}`;

        dispatch({
          type: Types.ADD_USER_PROFILE_PHOTO,
          payload: {
            user: { imgUrl: img },
          },
        });

        return res.data;
      })
      .catch((e) => {
        throw e;
      });
  }, []);

  // REMOVE PROFILE PHOTO
  const removeProfilePhoto = useCallback(async () => {
    return axios
      .delete(`/auth/image`)
      .then((res) => {
        dispatch({
          type: Types.REMOVE_USER_PROFILE_PHOTO,
          payload: {
            user: null, // just because user is needed, I send a random value (null - not used anywhere)
          },
        });

        return res.data;
      })
      .catch((e) => {
        throw e;
      });
  }, []);

  // UPDATE PROVIDER LOGO
  const updateProviderLogo = useCallback(async (id: string, file: File) => {
    const formData = new FormData();
    formData.append("file", file);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    return axios
      .put(`/provider/providerImage`, formData, config)
      .then((res) => {
        const provider = res.data;

        provider.logoURL = `${provider.logoURL}?rand=${Math.random()}`;

        dispatch({
          type: Types.EDIT_PROVIDER_INFO,
          payload: {
            provider: provider,
          },
        });

        return res.data;
      })
      .catch((e) => {
        throw e;
      });
  }, []);

  // REMOVE PROVIDER LOGO
  const removeProviderLogo = useCallback(async (id: string) => {
    return axios
      .delete(`/provider/providerImage`)
      .then((res) => {
        dispatch({
          type: Types.REMOVE_PROVIDER_LOGO,
          payload: {
            provider: null, // just because providers is needed, I send a random value (null - not used anywhere)
          },
        });

        return res.data;
      })
      .catch((e) => {
        throw e;
      });
  }, []);

  const memoizedValue = useMemo(
    () => ({
      isInitialized: state.isInitialized,
      isAuthenticated: state.isAuthenticated,
      user: state.user,
      provider: state.provider ?? ({} as AuthProviderType),
      method: "jwt",
      login,
      register,
      forgotPassword,
      changePassword,
      verify,
      logout,
      editProfile,
      editProviderInfo,
      editPassword,
      updateProfilePhoto,
      removeProfilePhoto,
      updateProviderLogo,
      removeProviderLogo,
    }),
    [
      state.isAuthenticated,
      state.isInitialized,
      state.user,
      state.provider,
      login,
      register,
      forgotPassword,
      changePassword,
      verify,
      logout,
      editProfile,
      editProviderInfo,
      editPassword,
      updateProfilePhoto,
      removeProfilePhoto,
      updateProviderLogo,
      removeProviderLogo,
    ]
  );

  return (
    <AuthContext.Provider value={memoizedValue}>
      {children}
    </AuthContext.Provider>
  );
}
