// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = "/dashboard";

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: "/login",
  register: "/register",
  forgotPassword: "/forgot-password",
  changePassword: "/reset-password",
  verify: "/verify",
};

export const PATH_PUBLIC = {
  profile: (id: string) => path("", `/public/organization/${id}`),
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    root: path(ROOTS_DASHBOARD, "/general"),
    dashboard: path(ROOTS_DASHBOARD, "/general/dashboard"),
    providerDashboard: path(ROOTS_DASHBOARD, "/general/provider-dashboard"),
  },
  profile: path(ROOTS_DASHBOARD, "/profile"),
  requests: {
    root: path(ROOTS_DASHBOARD, "/requests"),
    list: path(ROOTS_DASHBOARD, "/requests/list"),
    create: (goAmount: string) =>
      path(ROOTS_DASHBOARD, `/requests/create/${goAmount}`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/requests/${id}/view`),
  },
  organizations: {
    root: path(ROOTS_DASHBOARD, "/organizations"),
    list: path(ROOTS_DASHBOARD, "/organizations/list"),
    create: path(ROOTS_DASHBOARD, "/organizations/create"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/organizations/${id}/edit`),
    profile: (id: string) =>
      path(ROOTS_DASHBOARD, `/organizations/${id}/profile`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/organizations/${id}/view`),
  },
  locations: {
    root: path(ROOTS_DASHBOARD, "/locations"),
    list: path(ROOTS_DASHBOARD, "/locations/list"),
    listByOrgId: (orgId: string) =>
      path(ROOTS_DASHBOARD, `/locations/organization/${orgId}/list`),
    createByOrgId: (orgId?: string) =>
      path(ROOTS_DASHBOARD, `/locations/organization/${orgId}/create`),
    create: () => path(ROOTS_DASHBOARD, `/locations/create`),
    edit: (orgId: string, id: string) =>
      path(
        ROOTS_DASHBOARD,
        `/locations/organization/${orgId}/location/${id}/edit`
      ),
    view: (orgId: string, id: string) =>
      path(
        ROOTS_DASHBOARD,
        `/locations/organization/${orgId}/location/${id}/view`
      ),
    location: (orgId: string, id: string, tabId?: string) =>
      path(
        ROOTS_DASHBOARD,
        `/locations/organization/${orgId}/location/${id}/view/${tabId}`
      ),
  },
  certificates: {
    root: path(ROOTS_DASHBOARD, "/certificates"),
    list: path(ROOTS_DASHBOARD, "/certificates/list"),
    create: path(ROOTS_DASHBOARD, "/certificates/create"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/certificates/${id}/edit`),
  },
  "provider-certificates": {
    root: path(ROOTS_DASHBOARD, "/provider-certificates"),
    list: path(ROOTS_DASHBOARD, "/provider-certificates/list"),
    create: path(ROOTS_DASHBOARD, "/provider-certificates/create"),
    sendPdf: (requestId: string) =>
      path(
        ROOTS_DASHBOARD,
        `/provider-certificates/request/${requestId}/create`
      ),
  },
  "provider-requests": {
    root: path(ROOTS_DASHBOARD, "/provider-requests"),
    list: path(ROOTS_DASHBOARD, "/provider-requests/list"),
    view: (id: string) =>
      path(ROOTS_DASHBOARD, `/provider-requests/${id}/view`),
  },
  leads: {
    root: path(ROOTS_DASHBOARD, "/leads"),
    list: path(ROOTS_DASHBOARD, "/leads/list"),
  },
  orders: {
    root: path(ROOTS_DASHBOARD, "/orders"),
    list: path(ROOTS_DASHBOARD, "/orders/list"),
    logs: path(ROOTS_DASHBOARD, "/orders/logs"),
    completed: path(ROOTS_DASHBOARD, "/orders/completed"),
    createSellOrder: path(ROOTS_DASHBOARD, "/orders/sell/create"),
    createBuyOrder: path(ROOTS_DASHBOARD, "/orders/buy/create"),
  },
  "provider-profile": path(ROOTS_DASHBOARD, "/provider-profile"),
  "provider-info": path(ROOTS_DASHBOARD, "/provider-info"),
  spaces: {
    root: path(ROOTS_DASHBOARD, "/spaces"),
    list: path(ROOTS_DASHBOARD, "/spaces/list"),
    create: path(ROOTS_DASHBOARD, "/spaces/create"),
    createSuccess: path(ROOTS_DASHBOARD, "/spaces/create/success"),
    createError: path(ROOTS_DASHBOARD, "/spaces/create/error"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/spaces/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/spaces/${id}/view`),
  },
  vehicles: {
    root: path(ROOTS_DASHBOARD, "/vehicles"),
    list: path(ROOTS_DASHBOARD, "/vehicles/list"),
    create: path(ROOTS_DASHBOARD, "/vehicles/create"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/vehicles/${id}/edit`),
    view: (id: string) => path(ROOTS_DASHBOARD, `/vehicles/${id}/view`),
    vehicle: (id: string, tabId?: string) =>
      path(ROOTS_DASHBOARD, `/vehicles/${id}/vehicle/${tabId}`),
  },
  chargers: {
    root: path(ROOTS_DASHBOARD, "/chargers"),
    list: path(ROOTS_DASHBOARD, "/chargers/list"),
    create: path(ROOTS_DASHBOARD, "/chargers/create"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/chargers/${id}/edit`), // todo
    view: (id: string) => path(ROOTS_DASHBOARD, `/chargers/${id}/view`), // todo
    charger: (id: string, tabId?: string) =>
      path(ROOTS_DASHBOARD, `/chargers/${id}/charger/${tabId}`),
  },
  meters: {
    root: path(ROOTS_DASHBOARD, "/meters"),
    list: path(ROOTS_DASHBOARD, "/meters/list"),
    create: path(ROOTS_DASHBOARD, "/meters/create"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/meters/${id}/edit`),
    meter: (id: string, tabId?: string) =>
      path(ROOTS_DASHBOARD, `/meters/${id}/meter/${tabId}`),
  },
  pvs: {
    root: path(ROOTS_DASHBOARD, "/pvs"),
    list: path(ROOTS_DASHBOARD, "/pvs/list"),
    create: path(ROOTS_DASHBOARD, "/pvs/create"),
    edit: (id: string) => path(ROOTS_DASHBOARD, `/pvs/${id}/edit`),
    pv: (id: string, tabId?: string) =>
      path(ROOTS_DASHBOARD, `/pvs/${id}/pv/${tabId}`),
  },
  "file-manager": {
    root: path(ROOTS_DASHBOARD, "/file-manager"),
    list: path(ROOTS_DASHBOARD, "/file-manager/files"),
  },
};
