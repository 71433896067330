// ----------------------------------------------------------------------------------

export enum SpaceDataTypes {
  CONSUMPTION = "consumption",
  PRODUCTION = "production",
  EV = "ev",
  EVSE = "evse",
}

export enum SpaceConsumptionSource {
  KEON_API = "keonapi",
  CUSTOM_API = "customapi",
  BILLS = "bills",
  CSV = "csv",
}

export enum SpaceProductionSource {
  SUPPORTED = "supported",
  KEON_API = "keonapi",
  CUSTOM_API = "customapi",
  CSV = "csv",
}

export enum SpaceEVSource {
  SUPPORTED = "supported",
}

export enum SpaceEVSESource {
  SUPPORTED = "supported",
  OCPP = "ocpp",
}

export type CombinedSpaceIntegrationTypes =
  | SpaceConsumptionSource
  | SpaceProductionSource
  | SpaceEVSource
  | SpaceEVSESource;

export enum SpaceState {
  PENDING = "pending",
  ACTIVE = "active",
  INACTIVE = "inactive",
  ARCHIVED = "archived",
  DELETED = "deleted",
}

// ----------------------------------------------------------------------------------

export enum SpaceStatus {
  Active = "active",
  Archived = "archived",
}

export enum SpaceEntryStatus {
  All = "all",
}

export enum SpaceType {
  Consumption = "consumption",
  Production = "production",
  EV = "ev",
  Chargers = "evse",
  // Weather = "weather",
}

export enum SpaceIntegration {
  KEON_API = "keonapi",
  CUSTOM_API = "customapi",
  OCPI = "ocpi",
  OCPP = "ocpp",
  EBill = "bills",
  CSV = "csv",
  SUPPORTED_INVERTERS = "supported",
  CONNECT_WITH_SUPPORTED_EV_MANUFACTURERS = "supported",
}

export enum SpaceEntryEBillType {
  Actual = "actual",
  Estimation = "estimation",
}

// ----------------------------------------------------------------------------------

interface Organization {
  _id: string;
  name: string;
  logoURL: string;
}

interface Location {
  _id: string;
  name: string;
}

export interface ISpaceTableView {
  _id: string;
  dataType: string;
  intergrationSource: string;
  state: string;
  createdBy: string;
  createdAt: string;
  description: string;
  name: string;
  archived: boolean;
  organization: Organization;
  location: Location;
}

// interface ISpaceDetailsViewInfo {
//   id: string;
//   userId: string;
//   vendor: string;
//   isReachable: boolean;
//   lastSeen: Date;
//   locationId: string | null;
//   information: Information;
//   chargeState: ChargeState;
//   smartChargingPolicy: SmartChargingPolicy;
//   location: Location;
//   odometer: Odometer;
//   capabilities: Capabilities;
//   scopes: string[];
// }

// interface Information {
//   displayName: string | null;
//   vin: string;
//   brand: string;
//   model: string;
//   year: number;
// }

// interface ChargeState {
//   chargeRate: number | null;
//   chargeTimeRemaining: number | null;
//   isFullyCharged: boolean;
//   isPluggedIn: boolean | null;
//   isCharging: boolean | null;
//   batteryLevel: number | null;
//   range: number | null;
//   batteryCapacity: number | null;
//   chargeLimit: number;
//   lastUpdated: Date | null;
//   powerDeliveryState: string;
//   maxCurrent: number | null;
// }

// interface SmartChargingPolicy {
//   deadline: Date | null;
//   isEnabled: boolean;
//   minimumChargeLimit: number;
// }

interface Location {
  lastUpdated: Date | null;
  longitude: number | null;
  latitude: number | null;
}

// interface Odometer {
//   distance: number | null;
//   lastUpdated: Date | null;
// }

// interface Capabilities {
//   information: CapabilityDetail;
//   chargeState: CapabilityDetail;
//   location: CapabilityDetail;
//   odometer: CapabilityDetail;
//   setMaxCurrent: CapabilityDetail;
//   startCharging: CapabilityDetail;
//   stopCharging: CapabilityDetail;
//   smartCharging: CapabilityDetail;
// }

// interface CapabilityDetail {
//   interventionIds: string[];
//   isCapable: boolean;
// }

export interface ISpaceDetailsView {
  _id: string;
  organizationId: string;
  dataType: SpaceDataTypes;
  intergrationSource: CombinedSpaceIntegrationTypes;
  state: SpaceState;
  refId: string;
  createdAt: Date;
  updatedAt: Date;
  description: string;
  locationId: string;
  name: string;
  isShared: boolean;
  // info: ISpaceDetailsViewInfo;
  info: any;
}

export interface ISpaceUpdate {
  _id: string;
  name: string;
  description: string | null | undefined;
  organizationId: string | null | undefined;
  locationId: string | null | undefined;
  dataType: SpaceDataTypes | undefined;
  intergrationSource: CombinedSpaceIntegrationTypes | undefined;
  isShared: boolean;
}

export interface ISpaceCreate {
  name: string;
  description: string | null | undefined;
  organizationId: string | null | undefined;
  locationId: string | null | undefined;
  dataType: SpaceDataTypes | null | undefined;
  intergrationSource: CombinedSpaceIntegrationTypes | null | undefined;
  isShared: boolean;
}

// ------------------------------------------
export interface ISpaceView {
  _id: string;
  name: string;
  description: string | null | undefined;
  organization: { _id: string; name: string; sector: string; logoURL: string };
  location: { _id: string; name: string };
  type: SpaceType | undefined;
  integrationType: SpaceIntegration | undefined;
  isShared: boolean;
}

export interface ISpaceEntryTableView {
  _id: string;

  billingPeriod: string;
  consumption: string;
  energyCost: number;
  billCost: number;
  type: SpaceEntryEBillType;
  provider: string;
  vat: string;
  supplyNumber: string;
  billNumber: string;
  fileURL: string;

  archived: boolean;
  createdAt: Date;
  updatedAt: Date;
}

type IFileShared = {
  id: string;
  name: string;
  email: string;
  avatarUrl: string;
  permission: string;
};

export type ISpaceFileManager = {
  id: string;
  name: string;
  size: number;
  type: string;
  url: string;
  tags: string[];
  isFavorited: boolean;
  shared: IFileShared[] | null;
  createdAt: Date | number | string;
  modifiedAt: Date | number | string;
};
