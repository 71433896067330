export enum Role {
  StandardUser = "standard",
  EnergyProvider = "provider",
  SuperAdmin = "superadmin"
}

export const RoleTitle: { [key in Role]: string } = {
  [Role.StandardUser]: "role_standard",
  [Role.EnergyProvider]: "role_provider",
  [Role.SuperAdmin]: "role_superadmin",
};
