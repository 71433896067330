// @mui
import { IconButton, IconButtonProps } from "@mui/material";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// config
import { NAV } from "../../../config-global";
// components
import Iconify from "../../../components/iconify";
import { useSettingsContext } from "../../../components/settings";

// ----------------------------------------------------------------------

export default function NavToggleButton({ sx, ...other }: IconButtonProps) {
  const { themeLayout, onToggleLayout } = useSettingsContext();

  const isDesktop = useResponsive("up", "lg");

  if (!isDesktop) {
    return null;
  }

  return (
    <IconButton
      size="small"
      onClick={onToggleLayout}
      sx={{
        p: 0.5,
        top: 32,
        position: "fixed",
        left: NAV.W_DASHBOARD - 12,
        bgcolor: "background.default",
        zIndex: (theme) => theme.zIndex.appBar + 1,
        border: (theme) => `dashed 1px ${theme.palette.divider}`,
        "&:hover": {
          bgcolor: "background.default",
        },
        ...sx,
      }}
      {...other}
    >
      <Iconify
        width={16}
        icon={
          themeLayout === "vertical"
            ? "solar:alt-arrow-left-linear"
            : "eva:arrow-ios-forward-fill"
        }
      />
    </IconButton>
  );
}
