import Logo from "src/components/logo";
import { StyledRoot, StyledSection } from "./styles";
import AuthVerifyForm from "src/sections/auth/AuthVerifyForm";

// ----------------------------------------------------------------------

type Props = {
  title?: string;
  illustration?: string;
  children: React.ReactNode;
};

export default function VerifyLayout({ children, illustration, title }: Props) {
  return (
    <StyledRoot>
      <Logo
        sx={{
          zIndex: 9,
          position: "absolute",
          mt: { xs: 1.5, md: 5 },
          ml: { xs: 2, md: 5 },
        }}
      />

      <StyledSection>
        <AuthVerifyForm />
      </StyledSection>
    </StyledRoot>
  );
}
